import React from "react";

const Question: React.FC = () => {
  const faqs = [
    {
      question: "参加費はかかりますか？",
      answer: "いいえ、参加費は一切かかりませんのでご安心ください。",
    },
    {
      question: "エンジニア学生以外も参加することは可能ですか？",
      answer:
        "申し訳ございません。現状ではエンジニア学生様のみが参加可能となっております。",
    },
    {
      question: "もっと詳しく話を聞いてみたい。",
      answer:
        "X(旧Twitter)のDMで詳細をお話します。以下のアカウントまでご連絡ください。\n公式X（GROST）",
    },
  ];

  const XUrl = "https://x.com/grost_li";

  return (
    <div className="mb-8 md:mb-12 flex flex-col items-center px-4 md:px-0">
      <h2 className="text-2xl md:text-3xl font-bold mb-8 md:mb-12 relative">
        <span className="underline underline-offset-8">よくある質問</span>
      </h2>
      <div className="space-y-6 md:space-y-8 max-w-5xl w-full">
        {faqs.map((faq, index) => (
          <div key={index} className="space-y-4 md:space-y-6 mx-0 md:mx-28">
            <div className="flex items-start">
              <span className="w-8 h-8 md:w-12 md:h-12 rounded-lg bg-emerald-400 flex items-center justify-center text-white mr-2 text-xl md:text-2xl flex-shrink-0">
                Q
              </span>
              <p className="flex-1 pt-1 pl-2 md:pl-6 text-lg md:text-2xl">
                {faq.question}
              </p>
            </div>
            <div className="flex items-start">
              <span className="w-8 h-8 md:w-12 md:h-12 rounded-lg bg-red-400 flex items-center justify-center text-white mr-2 mb-4 md:mb-12 text-xl md:text-2xl flex-shrink-0">
                A
              </span>
              <div className="flex-1 pt-1 pl-2 md:pl-6 whitespace-pre-line text-lg md:text-2xl">
                {faq.answer.includes("公式X") ? (
                  <>
                    X(旧Twitter)のDMで詳細をお話します。以下のアカウントまでご連絡ください。
                    <br />
                    <a
                      href={XUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-theme underline"
                    >
                      公式X（GROST）
                    </a>
                  </>
                ) : (
                  <>{faq.answer}</>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Question;
